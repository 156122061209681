// ==========================================================================
// Mobile Custom Styles
// ==========================================================================
/* ---------------------
    Top Bar
    --------------------- */

#sddm li a{
    padding-left: 3px;
    padding-right: 3px;
}
.dark-bar .container{
    padding-left: 0;
    padding-right: 0;
}
.searchbar input {
    width: 70px;
}
.searchbar input:focus {
    width: 80px;
}
/* ---------------------
	 Header
	 --------------------- */
.header h1 {
	font-size: 150%;
}
.header .col-8{
    margin-top: 0px !important;
}
/* ---------------------
	 Navbar
	 --------------------- */
.navbar .container {
  padding-left: 0px;
  padding-right: 0px;
}
.menuzord-menu {
    background-color: @color-text !important;
}
.menuzord-menu li .dropdown li{
    background-color: lighten(@color-text, 10%) !important;
}
.menuzord-menu li .dropdown li .dropdown li{
    background-color: lighten(@color-text, 20%)  !important;
}
.menuzord-menu a{
    color: @color-navbar !important;    
}
.menuzord-menu ul.dropdown li a{
    color: lighten(@color-navbar, 20%)  !important;
}
.mobilemenu {
    display: inline-block;
    left: 23px;
    font-size: 24px;
    position: absolute;
    z-index: 100;
}
.navphone, .navmap {
    border-right-color: @color-navbar-text;
    border-right-style: solid;
    border-right-width: 1px;
    color: @color-navbar-text;
    margin-left: 9px;
    padding-bottom: 10px;
    padding-right: 13px;
    padding-top: 12px;
    position: relative;
}
.navmap {
    border-left-color: @color-navbar-text;
    border-left-style: solid;
    border-left-width: 1px;
    padding-left: 10px;
}

.navbar.sticky .menuzord-menu:before {
    position: absolute;
    margin-left: 0;
    display: none;
}

.sticky .mobilemenu::after {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../images/logo.png");
    background-origin: padding-box;
    background-position: 5px center;
    background-repeat: no-repeat;
    background-size: 30px auto;
    content: "";
    height: 40px;
    left: -40px;
    top: 4px;
    position: absolute;
    width: 40px;
}
.sticky .mobilemenu{
    left: 55px;    
}
.sticky .menuzord ul li{
    padding-top: 0px;    
}
.navbar .col{
    padding: 0;
}
.sticky{
    opacity: 0.9;    
}
.sticky.scrolls {
    bottom: 0;
    overflow-y: scroll;
    top: 0;
}
/* ---------------------
	 Banner
	 --------------------- */
.banner-slide { 
  height: 125px;														// IE 8> fallback
	height: calc(@banner-height / 2);
}
.ccms-banner{
    padding: 52px 100px;//109px 100px;
    .ccms-banner-overlay {display:none;}
}
.home-full-banner .ccms-banner{
    padding: 52px 100px;//159px 100px;
}
.teacher-home .banner {
    margin-top: 0px;
    height: 320px;
}
.teacher-home .banner.col{
    padding: 0;
    }
.slick-dots{
    display: none !important;
}
/* ---------------------
	 whats new
	 --------------------- */
.whats-new{
    margin: 0 auto !important;
    margin-top: 20px !important;
    width: 96.5% !important;
}
.teacher-home .whats-new {
    margin-bottom: 40px !important;
}

/* ---------------------
	 Dashboard
	 --------------------- */
.dashboard-item { min-height: 170px; }


/* ---------------------
   Footer
   --------------------- */
.mobile-list-styled li {
  display: block !important;
  margin-bottom: 0.5em !important;
}