
/* =======================
   Slick Core
   ======================= */

.slick-slider {
  background: #000;
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus { outline: none; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  background: #000;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide { float: right; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* =======================
   Arrows
   ======================= */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: -10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before { opacity: 1; }
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before { opacity: .25; }
.slick-prev:before,
.slick-next:before {
  font-family: @font-icon;
  font-size: 30px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev { /* left: -25px; */ }
[dir='rtl'] .slick-prev { /* right: -25px; */; left: auto; }
.slick-prev:before { content: '\f053'; }
[dir='rtl'] .slick-prev:before { content: '\f054'; }
.slick-next { /* right:  -25px; */ }
[dir='rtl'] .slick-next { right: auto; /* left: -25px; */ }
.slick-next:before { content: '\f054'; }
[dir='rtl'] .slick-next:before { content: '\f053'; }

/* =======================
   Dots
   ======================= */
.slick-slider {margin-bottom: 30px;}
.slick-dots {
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px !important;
  height: 10px !important;
  margin: 0 0px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px !imprtant;
  height: 10px !important;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus { outline: none; }
.slick-dots li button:hover:before,
.slick-dots li button:focus:before { opacity: 1; }
.slick-dots li button:before {
  /*font-family: @font-icon;
  font-size: 36px !important;*/
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px !important;//20px;
  height: 10px !important;//20px;
  content: '' !important;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}
