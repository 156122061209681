
#sddm {
	z-index: 30; 
	display: inline-block;
	margin: 0;
	padding: 0;

}
#sddm i {font-size: 14px;}

#sddm li a {
  display: inline-block;
  color: #fff;
  background: none;
  font-size: 12px;
  padding: 5px 10px;
  margin: 0;
  border: 0px solid;
  -webkit-transition: all .0s;
  -moz-transition: all .0s;
  -o-transition: all .0s;
  -ms-transition: all .0s;
  transition: all .0s;
}
#sddm li a:hover {background-color: darken(@color-primary, 10%);}

#sddm div {
	position: absolute;
	visibility: hidden;
	padding: 5px;
	background: #fff;
	border: 1px solid #bababa;
	border-top: 0px solid #fff;
}
#sddm div a {
	display: block;
	min-width: 130px;
	white-space: nowrap;
	font-family: @font-primary;
	font-size: 85%;
	color: #222;
	border-width: 1px 0px 0px;
	border-style: solid;
	border-color: #E9E9E9;
}
#sddm div a:hover {background: #E9E9E9;}