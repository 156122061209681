// ==========================================================================
// Desktop Custom Styles
// ==========================================================================

/********** ADA **********/
.ada
{
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	left: -9999em;
}
/********** /ADA **********/

/* --------------------- 
	 Body
	 --------------------- */

.background-gradient{
    #gradient > .horizontal-three-colors(darken(@color-body, 12%), @color-body, @colorStop: 50%, darken(@color-body, 12%));
}

html,
body {
	.background-gradient();
	overflow: auto;
}

/* ---------------------
	 Topbar
	 --------------------- */
.navigation {
	list-style: none;
	display: inline;
	margin: 0;
	padding: 0;
}
.navigation li {display: inline;}

.topbar .social-button, .topbar .topbar-tel {
	padding: 5px 15px;
	display: inline block;
}
.hover_effect,.hover_effect1,.hover_effect2{
   visibility: visible !important;
}

/* ---------------------
	 Header
	 --------------------- */
.header{.background-gradient();}

.header h1, .header h3 { margin: 0; }
.header h3 { font-size: 17px;}
.header .logo {
    float: left;
    margin-right: 20px;
}
.header .logo img { max-height: 110px; }
.header .col-7 {
    margin-top: 20px !important;
}
/* Searchbar */
.searchbar {
	.border-radius(0px);
  overflow: hidden;
  position: relative;
  font-size: 16px;
  width: inherit;
  margin: 0 auto;
    display: inline-block;
    vertical-align: middle;
}
.searchbar button {
	position: absolute;
	width: auto;
	right: 0;
    top:0;
	height: 100%;
	background-color: transparent;
}
.searchbar .fa-search {color: #fff;}

.searchbar form {
    padding-right: 30px;
}
.searchbar input {
    background-color: transparent;
    color: #fff !important;
    font-size: 12px;
    height: 100%;
    line-height: 18px;
    padding-right: 0;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: ease;
    width: 130px;
}
.searchbar input:focus {
    background-color: rgba(255, 255, 255, 0.1);
    margin-right: 5px;
    outline-color: -moz-use-text-color;
    outline-style: none;
    outline-width: medium;
    width: 250px;
}
.searchbar .fa-search {
    color: #fff;
}
.searchbar span.ada{color:#fff;}

.header .googleMap:hover {
    text-decoration: underline;
}
.title-nav {
    list-style-type: none;
   // margin-top: 31px;
    padding: 0;
}
.title-nav li {
    display: inline-block;
}
.title-nav a {
    color: #fff;
    display: inline-block;
    font-size: 70%;
    margin-left: 20px;
    text-align: center;
}
.title-nav a:hover {
    opacity: 0.5;
}
.title-nav a .fa {
    display: block;
    margin-bottom: 5px;
    text-align: center;
    width: 100%;
}
.tagline-contained {
    list-style: outside none none;
    padding: 0;
    margin: 0;
}
/* ---------------------
	 Navigation
	 --------------------- */
.mobilemenu{
    display: none;
}
.sticky {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 20;
    border-top: 0;
}
.sticky .menuzord ul {
    margin: 0 auto;
}
.sticky .menuzord ul ul li{
    padding-top: 0px;    
}
/* ---------------------
	 Banner
	 --------------------- */
.banner{
	position: relative;
	margin-bottom: -@banner-undertuck;
	z-index: 10;
}

.teacher-home.banner {
    margin-bottom: -0px;
}

.banner.subpage{
    height: @banner-height-subpage + 10;
    margin-bottom: 20px;
}
.slider, .whats-new {
    border: 8px solid #fff;
    height: @banner-height;
    overflow: hidden;
	#shadow > .box-shadow(0, 0px, 20px, 0.5);
}
.content .sub .slider{
    margin-top: 0px;
}
.banner-slide {
	&:extend(.background-cover);
}
.banner.subpage #subbanner { height: @banner-height-subpage; background-position: 50% 50%; background-size: cover;}
.slick-slider { margin-bottom: 0; }

.ccms-banner{
    height: @banner-height;
}
.ccms-banner::after {
    background: none;
}
a.ccms-banner-button, a.ccms-banner-button:link, a.ccms-banner-button:visited{
    padding: 10px;
}
/* ---------------------
	 breadcrumb
	 --------------------- */
.breadcrumb{opacity: .5;}
.breadcrumb a:hover{opacity: 1;}

/* ---------------------
	 Content
	 --------------------- */
.content {
	background: #fff;
	#shadow > .box-shadow(0, 0px, 20px, 0.3);
}

.content.home {padding-top: @banner-undertuck;}

.nav-content{
    padding-top: 0px;
}
//search
.header-search-field {
    -x-system-font: none;
    background-color: #fff;
    background-image: none;
    border-bottom-color: #999;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 0;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: #999;
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: #999;
    border-right-style: solid;
    border-right-width: 0;
    border-top-color: #999;
    border-top-left-radius: 7px;
    border-top-right-radius: 0;
    border-top-style: solid;
    border-top-width: 1px;
    box-sizing: border-box;
    color: #53585c;
    float: left;
    font-family: "PT Sans",sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-size: 14px;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-synthesis: weight style;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-weight: normal;
    height: 35px;
    line-height: 21px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 0;
    vertical-align: top;
    width: 164px;
}
.header-search-button {
    box-sizing: border-box;
    color: #555;
    height: 35px;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    vertical-align: top;
}
//teacher
.teacher-home .banner {
    margin-top: -33px;
    float: right;
}

.teacher-home .whats-new {
    margin-top: -33px !important;
}
.content.teacher-subpage {
    padding-top: 25px;
}
.teacher-home .main-article {
    float: right;
}
.teacher-home-info{
    text-align: center;
}
.teacher-image{
    max-height: 185px;
    max-width: 350px;
}
.teacher-room, .teacher-phone, .teacher-email {
    display: block;
}
/* ---------------------
	 What's New
	 --------------------- */
.content.home .col-4 .padded{
    /*padding-top: 0px;*/
}
.content.home .col-4 .catapultTitle:first-of-type{
    margin-top: 5px !important;
}

.whats-new{ background: #fff; }
.marquee-wrapper { 
    border: 1px solid #ccc;
    box-shadow: 0px 0px 15px rgba(0,0,0,.5);
    margin-bottom:15px;
}
.home-no-scroll.marquee-wrapper { overflow:auto; height: 331px; }
.marquee { overflow: hidden; }
.ver {
  height: 331px;
  width: 100%;
}

.rspPane .ver {
    height: auto;
}

.marquee-wrapper.padded {
    /*padding: 3px;*/
}
/* ---------------------
	 Sidenav
	 --------------------- */
.sidenav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.sidenav a {
	display: block;
	background: fade(@color-primary, 15%);
	color: #666;
	width: 100%;
	padding: 5px;
	margin-bottom: 1px;
	.transition(all 0.2s ease);
}
.sidenav a:before {
	content:'\f0da';
	font-family: @font-icon;
	margin-right: 8px;
}
.sidenav a:hover {
	background: @color-primary;
	color: #fff;
}
.sidenav > ul a {padding-left: 10px;}
.sidenav > ul > li > ul a {padding-left: 20px;}
.sidenav > ul > li > ul > li > ul a {padding-left: 35px;}
.sidenav > ul > li > ul > li > ul > li > ul a {padding-left: 50px;}

/* ---------------------
	 Dashboard
	 --------------------- */
.dashboard-item {
	border: 5px solid #ddd;
	box-shadow: 0 0 0 1px #ccc;
	display: block;
	background-repeat: no-repeat;
	background-position: bottom right;
	color: #454545;
	min-height: 220px;
	position: relative;
	margin-bottom: 20px;
	.transition(all 0.2s ease);

    h3 {
        padding-top: 10px;
    }

    p {
        padding-right: 100px;
        color: #666;
    }
    .button {
        position: absolute;
        bottom: 15px;
        left: 15px;
        #gradient > .vertical(#fff,#ddd);
        .transition(all 0.2s ease);
    }
    &:hover {
        border-color: @color-link;
        box-shadow: 0 0 0 1px @color-link;
    }
    &:hover .button {
      color: #fff;
      #gradient > .vertical(@color-tertiary,darken(@color-tertiary, 10%));
    }
}
/* ---------------------
	 Bottom Nav
	 --------------------- */

.bottomnav ul { position:relative; text-align:center; list-style: none; margin:0; padding:0; }
.bottomnav ul li { display:inline-block; margin: 0 0 0 8px; padding: 0 0 0 8px; border-left:1px solid @color-link; /* For IE 7 */ zoom: 1; *display: inline; }
.bottomnav ul li:first-child { border-left:none; margin: 0 0 0 0; padding: 0 0 0 0; }
.bottomnav ul li a { display:block; line-height:1; text-decoration: none; color:@color-link; }
.bottomnav ul li a:hover { text-decoration:underline; }

/* ---------------------
	 Footer
	 --------------------- */
.footer {.background-gradient();

    .quicklinks div {color:#fff;}
    
}

.quicklinks .FW_EDITOR_STYLE ul{
	//.columns(@width: 50%, @count: 2, @gap: 20px);
	list-style: none;
	margin-bottom: 20px;
	padding: 0;
}
.quicklinks .FW_EDITOR_STYLE li:before {
	content:"\f0da";
	font-family: @font-icon;
	margin-right: 10px;
}
.footer a:hover, .dark-bar a:hover {text-decoration: underline;}
.footer .catapultTitle {color:#ffffff;}
.footer ul li{margin: 7px 0px;}
.subpage-footer ul li{display:inline; margin: 0px 10px;}
.footer .client-title {
	position:relative;
	margin: 15px 0px 10px 0px;
	padding: 0 20px 5px 0px;
	font: normal normal 20px/1.5;
	text-decoration:none;
	text-transform:none;
}
/* ---------------------
	 Template Specific Helpers
	 --------------------- */
.dark-bar { #gradient > .horizontal-three-colors(darken(@color-body, 17%), darken(@color-body, 5%), @colorStop: 50%, darken(@color-body, 17%)); }

.complex-shadow {position: relative;}
.complex-shadow:before {
	content:"";
	display: block;
  position:absolute; 
  top: 0px;
  height: 42px;
  width: 100%;
  background-image: url(../images/shadow2.png);
  background-repeat: no-repeat;
  background-position: center top;

}
.menuzord.complex-shadow:after {bottom: -32px;}

/* ---------------------
	 Copyright
	 --------------------- */

.sub-footer {
  display: block;
  padding: 18px 15px 16px 15px;
  background: darken(@color-primary, 15%);
}

.copyright {
    color: contrast(@color-secondary, desaturate(lighten(@color-secondary, 65%), 50%), darken(@color-secondary, 65%));
    a {color: contrast(@color-secondary, #ffffff, #111111); white-space: nowrap;}
    a:active, a:hover {color: mix(@color-link, contrast(@color-secondary, #ffffff, #111111));}
}

/* ---------------------
     Non Descrimination
     --------------------- */
.nondescrimination {
    display: inline-block;
    &:hover{
            a{color: mix(@color-link, contrast(@color-secondary, #ffffff, #111111));}
            i{background: @color-link; color:contrast(@color-link, #111, #FFF);}
        }
    a{display:inline; color:contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));}
    i{
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 10px;
        background: @color-tertiary;
        color: contrast(@color-tertiary, #111, #FFF); 
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        font-weight: bold;
        -webkit-transition: transform 0.1s ease;
        -moz-transition: transform 0.1s ease;
        -ms-transition: transform 0.1s ease;
        -o-transition: transform 0.1s ease;
        transition: transform 0.1s ease;
    }

}

.ada-contrast{
    display: inline-block;
    line-height: 32px;
    font-family:  @font-primary;
    
    a{
    color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
    line-height:50px;
    padding: 0px 10px;
        &:hover{
            text-decoration: underline;
        }
    }
}