// ==========================================================================
// Content Replacement
// ==========================================================================

.client-title:before {content: @client-title;}
.client-district:before {content: @client-district;}
.client-address-street:before {content: @client-address-street;}
.client-address-city:before {content: @client-address-city;}
.client-phone-main:before {content: @client-phone-main;}
.client-phone-attendance:before {content: @client-phone-attendance;}
.client-fax:before {content: @client-fax;}