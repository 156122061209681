// ==========================================================================
// Print Styles
// ==========================================================================

@media print {

* { background: transparent; }
a, a:visited { text-decoration: underline; }

abbr[title]:after { content: " (" attr(title) ")"; }

pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
thead { display: table-header-group; }
tr, img { page-break-inside: avoid; }
img { max-width: 100%; }
@page { margin: 0.5cm; }
p, h2, h3 { orphans: 3; widows: 3; }
h2, h3 { page-break-after: avoid; }

} // End Print Styles