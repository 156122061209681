
/*
<a name="<%CARD_TARGET%>"></a>
<h3 class="catapultTitle <%CARD_ICON_CLASS%>">
	<img src="<%CARD_ICON%>">
	<span class="se"></span>
	<span class="s"></span>
	<span class="sw"></span>
	<%CARD_NAME%>
	<a href="<%TOP_LINK%>" title="Top of Page"></a>
</h3>
*/

/********* default Catapult title ***********/
.catapultTitle {
	position:relative;
	margin: 15px 0px 10px 0px;
	padding: 0 20px 5px 45px;
	background: url('http://staging.development.catapultcms.com/153/images/content-title-border.png') left bottom repeat-x;
	font: normal normal 20px/1.5;
	text-decoration:none;
	text-transform:none;
}
.catapultTitle img {
	position:absolute;
	z-index:2;
    top: -10px;
	left: 0;   
}
.catapultTitle span {display:none;}
.catapultTitle a {
	display:block;
	position:absolute;
	right:0;
	bottom:15px;
	width:13px;
	height:12px;
	opacity:0.5;
	filter:alpha(opacity=50); /* For IE8 and earlier */
	display:none;
}

.catapultTitle a:hover { opacity:1; filter:alpha(opacity=100); /* For IE8 and earlier */ }
.catapultTitle.disabledIcon { padding-left:0; }
.catapultTitle.disabledIcon img { display:none; }
.catapultTitle.disabledIcon span {  }
.catapultTitle.disabledIcon a {  }
.catapultTitle.firstBoxTitle { margin-top:0px; }

/********* image banner content ***********/
.banner-content .catapultTitle { display:none; }

/********* what's new column content ***********/
.whats-new .catapultTitle {
	color: @color-primary;
  font-size: 12px;
  padding-bottom: 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
	font-weight:bold;
}
	
/********* resources-faq content ***********/
.resources-faq .catapultTitle {
  font-size: 125%;
  padding-bottom: 10px;
  transition: all 0.1s ease 0s;
  border-top: 1px dotted #ddd;
  margin: 0;
  padding: 18px 0 20px;
  position: relative;
}
.resources-faq .catapultTitle:after {
	content: "\f078";
	font-family: FontAwesome, sans-serif;
	position: absolute;
	right: 0;
	color: @color-link;
}
.resources-faq .catapultTitle a{ 
	display:none; 
	opacity:1;
}
.resources-faq .catapultTitle.firstBoxTitle { border-top: medium none; }

.resources-sidebar .catapultTitle {
  font-size: 13px;
  padding-bottom: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
	font-weight: bold;
}
	
.banner-column .catapultTitle img { display:none; }
.banner-column .catapultTitle a { display:none; }
.banner-column .catapultTitle.firstBoxTitle { margin-top:0px; }
