// ==========================================================================
// Helpers / Extensions
// ==========================================================================

// Floats
.left { float: left; }
.right { float: right; }

// Display
.block { display: block; }
.inline { display: inline; }
.inline-block { display: inline-block; *zoom: 1; *display: inline; }
.none, [hidden] {display: none;}

// Padding
.padded {padding: 15px;}

// Font Sizes
.font-m { font-size: 100% !important; }
.font-s { font-size: 85% !important; }
.font-xs { font-size: 75% !important; }
.font-l { font-size: 125% !important; }
.font-xl { font-size: 140% !important; }

// Font Weights
.font-ultra-bold { font-weight: 900 !important; }
.font-bold { font-weight: 700 !important; }
.font-normal { font-weight: 500 !important; }
.font-light { font-weight: 300 !important; }
.font-thin { font-weight: 100 !important; }

// Font Styles
.font-normal { font-style: normal; }
.capitalize { text-transform: capitalize; }
.uppercase { text-transform: uppercase; }
.letter-spacing { letter-spacing: 2px; }

// Text Shadow 
.text-shadow { #shadow > .text-shadow(1px, 2px, 0, 0.8); }

// Text Position
.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }

// Background Cover
.background-cover { background-size: cover; background-position: center center; }

// Image Spacing
.image-space-right { margin-right: 20px; }
.image-space-left { margin-left: 20px; }

// Colors
.text-white,
.text-white a {color: #fff;}

// Accessibility (offscreen text for screen readers)
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  left: -9999em;
}

