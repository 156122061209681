// ==========================================================================
// Buttons
// ==========================================================================

.button {
  padding: 3px 8px;
  text-decoration: none;
  &:extend(.text-center);
  &:extend(.inline-block);
  position: relative;
  font-size: 13px;
  .border-radius(5px);
  color: #666;
  border: 1px solid #ddd;
  #gradient > .vertical(#fff,#ccc);
  #shadow > .inner-shadow(0, -2px, 5px, 0.15);
}

.button:after {
  content:'\f054';
  margin-left: 10px;
  font-size: 80%;
  font-family: 'FontAwesome' !important;
}

.button:hover {
  color: #fff;
  #gradient > .vertical(@color-tertiary,darken(@color-tertiary, 10%));
}
.button:active {
  top: initial;
}

.grey-button {
  background-color: #eee;
  color: #434343;
}
.grey-button:hover {
  background-color: #f1f1f1;
}

.button[disabled],
.button.disabled {
  color: #999;
  background-color: #f1f1f1;
}

.button-unstyled {
  .font-size(@font-size);
  color: @color-link;
  text-decoration: underline;
  border: 0;
  background: transparent;
  height: auto;
  padding: 0;
  cursor: pointer;
  outline: 0;
  &:hover {
    text-decoration: none;
  } // end hover
}
.button-unstyled[disabled],
.button-unstyled.disabled {
  text-decoration: none;
  color: #999;
  background-color: #ccc;
}