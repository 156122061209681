// ==========================
// Style Variables
// ==========================

// Fonts
@font-primary:			  'Open Sans', Arial, sans-serif;
@font-secondary:		  'Open Sans', Arial, sans-serif;		
@font-icon:					  'FontAwesome';

// Text Sizes
@font-size:   				16px;
@line-height: 				1.6;
 
// Colors - Backgrounds
@color-primary:				#634b3a; // Brown
@color-secondary:			#8b1b13; // Red
@color-tertiary:			#6f6f6f; // Gray
@color-navbar:				#fff;
@color-body:				@color-primary;

// Colors - Type
@color-text:  				#454545;
@color-link:  				#7e5c24; // Lighter Brown
@color-line:				#ddd;
@color-link-hover: 		    darken(@color-link, 10%);
@color-navbar-text:		    #565656;

// ==========================
// Content Variables
// ==========================

@client-title: 'Wheatland School District';
@client-district: 'Wheatland School District';
@client-address-street: '111 Main Street, ';
@client-address-city: 'Wheatland, CA 95692';
@client-phone-main: '(530) 633-3130';
@client-phone-attendance: '';
@client-fax: '(530) 633-4807';