// ==========================================================================
// Navbarn - Menuzord
// Based on http://codecanyon.net/item/menuzord-responsive-megamenu/8536398
// ==========================================================================

.menuzord-menu > li.navlogo > a {display:none;}

.sticky {
    .menuzord-menu > li.navlogo > a {
        display:block;
        padding: 0px 16px;
        line-height: 40px;
        text-decoration: none;
    }

}

/* ===================
   Colors
   =================== */
.navbar { 
  #gradient > .vertical (@color-navbar, darken(@color-navbar, 15%));
  /* #shadow > .box-shadow(0, 0px, 20px, 0.3); */

-webkit-box-shadow: 0 10px 6px -6px rgba(0,0,0,0.59) !important;
	   -moz-box-shadow: 0 10px 6px -6px rgba(0,0,0,0.59) !important;
	        box-shadow: 0 10px 6px -6px rgba(0,0,0,0.59) !important;
}
.navbar .complex-shadow:after {
  background-image: none;
}
.menuzord .menuzord-menu > li > a { 
  color: @color-navbar-text;
  font-weight: bold;
}
/*.menuzord .menuzord-menu > li.active > a, 
.menuzord .menuzord-menu > li:hover > a, 
.menuzord .menuzord-menu ul.dropdown li:hover > a{ 
  #gradient > .vertical (darken(@color-navbar, 10%), 
                        darken(@color-navbar, 20%));
  color: @color-navbar-text;
} */
.menuzord .menuzord-menu > li.active > a, { 
  #gradient > .vertical (darken(@color-navbar, 10%), 
                        darken(@color-navbar, 20%));
  color: @color-navbar-text;
}
.menuzord .menuzord-menu > li:hover > a {
    background: lighten(@color-text, 56%);
    color: lighten(@color-text, 20%);

}
.menuzord .menuzord-menu ul.dropdown li:hover > a, 
.menuzord .menuzord-menu > li a:hover{
    background: lighten(@color-text, 30%);
    color: darken(@color-navbar-text, 30%) ;//@color-navbar-text;
}

/* ============================
   Menu Container
   ============================ */
.menuzord{
  width: 100%;
  float: left;
  position: relative;
  z-index: 99;
}

.menuzord a{ .transition(all 0.3s ease); }
.menuzord-menu.scrollable > .scrollable-fix{ display: none; }

/* ============================
   Menu
   ============================ */
.menuzord-menu{
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
  z-index: 99;
}

.menuzord-menu.menuzord-right{ float: right; }
.menuzord-menu > li{
  float: left;
}
.menuzord-menu > li > a{
  padding: 10px 15px;
  display: inline-block;
  text-decoration: none;
  font-size: @font-size;

  outline: 0;
  line-height: 1.42857143;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  border-left: 1px solid rgba(0,0,0,0.15);
}

/* ============================
   Dropdowns
   ============================ */
.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown{
  list-style: none;
    margin: 0;
    padding: 0;   
  display: none;
    position: absolute;
    z-index: 99;
  min-width: 180px;
  white-space: nowrap;
  background: lightgray;
  /* #shadow > .box-shadow(0, 0px, 20px, 0.3); */
}
.menuzord-menu ul.dropdown li ul.dropdown{
    left: 100%;
}
/* Dropdown items */
.menuzord-menu ul.dropdown li{
  clear: both;
  width: 100%;
  border: 0 none;
  font-size: 12px;
  position: relative;
}
.menuzord-menu ul.dropdown li a{
  width: 100%;
  background: none;
  padding: 10px 25px 10px 22px;
  color: darken(@color-navbar-text, 30%);

  text-decoration: none;
  display: inline-block;
  float: left;
  clear: both;
  position: relative;
  outline: 0;
  .transition(padding 0.2s);
}
/* Hover state (dropdown items) */
.menuzord-menu ul.dropdown li:hover > a{
  padding-left: 28px;
  padding-right: 19px;
  color: #fff;//darken(@color-text, 14%);
}

/* Dropdown left alignment */
.menuzord-menu ul.dropdown.dropdown-left{
  left: none;
}
.menuzord-menu ul.dropdown li ul.dropdown.dropdown-left{
  left: -100%;
}

/* ============================
   Dropdown Indicators
   ============================ */
.menuzord-menu li .indicator{ margin-left: 3px; }
.menuzord-menu li ul.dropdown li .indicator{
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 15px;
}

/* ============================
   Show / Hide Button
   ============================ */
.menuzord .showhide{
  width: 60px;
  height: 46px;
  padding: 13px 0 0;
  display: none;
  float: right;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
/* Show/hide button icon */
.menuzord .showhide em{
  width: 20px;
  height: 3px;
  margin: 3.5px 20px 0;
  float: right;
  background: @color-navbar-text;
}

/* ============================
   Mobile Mode
   ============================ */
@media (max-width: @mobile-breakpoint){
    
.menuzord .menuzord-menu > li:hover > a {
    background: lighten(@color-text, 8%);
    color: @color-navbar-text;
}
.menuzord .menuzord-menu ul.dropdown li:hover > a, 
.menuzord .menuzord-menu > li a:hover{
    background: darken(@color-text, 6%);
    color: @color-navbar;
}    
    
  .menuzord-responsive.menuzord{ padding: 0; }
  .menuzord-responsive .showhide{ margin: 0; }
  .menuzord-responsive .menuzord-menu{ width: 100%; float: left !important; }
  .menuzord-responsive .menuzord-menu.scrollable{ overflow-y: auto; -webkit-overflow-scrolling: touch; }
  .menuzord-responsive .menuzord-menu.scrollable > .scrollable-fix{ display: block; }
  
  /* Menu items */
  .menuzord-menu {
    background-color: lighten(@color-text, 8%) !important;
    }
  .menuzord-menu li .dropdown{
    background-color: lighten(@color-text, 12%) !important;    
  }    
  .menuzord-menu li .dropdown li .dropdown{
    background-color: lighten(@color-text, 16%) !important;    
  }
  .menuzord-menu ul.dropdown li a{
    color: @color-navbar-text !important;
  }
  .menuzord-responsive .menuzord-menu > li{ width: 100%; display: block; position: relative; }
  .menuzord-responsive .menuzord-menu > li > a{
    width: 100%;
    padding: 12px 20px;
    display: block;
    border-bottom: solid 1px #f0f0f0;
    border-left: none;
    border-color: rgba(255,255,255,0.3) !important;
  }
 .menuzord-responsive .menuzord-menu > li:first-child > a{ border-top: solid 1px rgba(255,255,255,0.3); }
  
  /* Dropdown */
  .menuzord-responsive .menuzord-menu ul.dropdown,
  .menuzord-responsive .menuzord-menu ul.dropdown li ul.dropdown{
    width: 100%;
    left: 0;
    position: static;
    border: none;
    background: #fff;
    float: left;
  }
  
  /* Dropdown items */
  .menuzord-responsive .menuzord-menu ul.dropdown li{ position: relative; }
  .menuzord-responsive .menuzord-menu ul.dropdown li a{ border-bottom: solid 1px #f0f0f0; }
  .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > a{ padding-left: 40px !important; }
  .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li > a{ padding-left: 60px !important; }
  .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li > a{ padding-left: 80px !important; }
  .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li:hover > a{ padding-left: 50px !important; }
  .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li:hover > a{ padding-left: 70px !important; }
  .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li:hover > a{ padding-left: 90px !important; }
  
  /* Dropdown/megamenu indicators */
  .menuzord-responsive .menuzord-menu li .indicator{    
    width: 60px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 22px;
    text-align: center;
    line-height: 43px;
    border-left: solid 1px #f5f5f5;
    border-color: rgba(255,255,255,0.3) !important;
  }
  .menuzord-responsive .menuzord-menu li ul.dropdown li .indicator{
    height: 38px;
    right: 0;
    top: 0;
    font-size: 18px;
    line-height: 36px;
  }
  
}

