// ==========================================================================
// Grid
// ==========================================================================

.container {
  max-width: @desktop-container !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: @container-padding;
  padding-right: @container-padding;
  position: relative;
}

span.container {
    position: static;
}

// Columns
.col {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

[class*="pull-"], [class*="push-"] { position: relative; }

.no-gutter {
  padding-left: 0;
  padding-right: 0;
}

.col-1 {
  width: 8.33333%; // Fallback if calc() is not supported
 // width: calc(~'100% / 12 * 1');
  width: -webkit-calc(~'100% / 12 * 1');
  width: -moz-calc(~'100% / 12 * 1');
}
.col-2 {
  width: 16.66667%; // Fallback if calc() is not supported
 // width: calc(~'100% / 12 * 2');
  width: -webkit-calc(~'100% / 12 * 2');
  width: -moz-calc(~'100% / 12 * 2');
}
.col-3 {
  width: 25%; // Fallback if calc() is not supported
 // width: calc(~'100% / 12 * 3');
  width: -webkit-calc(~'100% / 12 * 3');
  width: -moz-calc(~'100% / 12 * 3');
}
.col-4 {
  width: 33.33333%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 4');
  width: -webkit-calc(~'100% / 12 * 4');
  width: -moz-calc(~'100% / 12 * 4');
}
.col-5 {
  width: 41.66665%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 5');
  width: -webkit-calc(~'100% / 12 * 5');
  width: -moz-calc(~'100% / 12 * 5');
}
.col-6 {
  width: 50%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 6');
  width: -webkit-calc(~'100% / 12 * 6');
  width: -moz-calc(~'100% / 12 * 6');
}
.col-7 {
  width: 58.33333%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 7');
  width: -webkit-calc(~'100% / 12 * 7');
  width: -moz-calc(~'100% / 12 * 7');
}
.col-8 {
  width: 66.66666%; // Fallback if calc() is not supported
 //width: calc(~'100% / 12 * 8');
  width: -webkit-calc(~'100% / 12 * 8');
  width: -moz-calc(~'100% / 12 * 8');
}
.col-9 {
  width: 75%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 9');
  width: -webkit-calc(~'100% / 12 * 9');
  width: -moz-calc(~'100% / 12 * 9');
}
.col-10 {
  width: 83.33333%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 10');
  width: -webkit-calc(~'100% / 12 * 10');
  width: -moz-calc(~'100% / 12 * 10');
}
.col-11 {
  width: 91.66666%; // Fallback if calc() is not supported
  //width: calc(~'100% / 12 * 11');
  width: -webkit-calc(~'100% / 12 * 11');
  width: -moz-calc(~'100% / 12 * 11');
}
.col-12 { width: 100%; }

.col-1-2 { &:extend(.col-6); }
.col-1-3 { &:extend(.col-4); }
.col-1-4 { &:extend(.col-3); }
.col-3-4 { &:extend(.col-9); }

// Push/Pull
.push-1 {
  left: 8.33333%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 1');
  left: -webkit-calc(~'100% / 12 * 1');
  left: -moz-calc(~'100% / 12 * 1');
}
.pull-1 {
  left: -8.33333%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 1');
  left: -webkit-calc(~'-100% / 12 * 1');
  left: -moz-calc(~'-100% / 12 * 1');
}
.push-2 {
  left: 16.66667%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 2');
  left: -webkit-calc(~'100% / 12 * 2');
  left: -moz-calc(~'100% / 12 * 2');
}
.pull-2 {
  left: -16.66667%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 2');
  left: -webkit-calc(~'-100% / 12 * 2');
  left: -moz-calc(~'-100% / 12 * 2');
}
.push-3 {
  left: 25%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 3');
  left: -webkit-calc(~'100% / 12 * 3');
  left: -moz-calc(~'100% / 12 * 3');
}
.pull-3 {
  left: -25%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 3');
  left: -webkit-calc(~'-100% / 12 * 3');
  left: -moz-calc(~'-100% / 12 * 3');
}
.push-4 {
  left: 33.33333%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 4');
  left: -webkit-calc(~'100% / 12 * 4');
  left: -moz-calc(~'100% / 12 * 4');
}
.pull-4 {
  left: -33.33333%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 4');
  left: -webkit-calc(~'-100% / 12 * 4');
  left: -moz-calc(~'-100% / 12 * 4');
}
.push-5 {
  left: 41.66665%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 5');
  left: -webkit-calc(~'100% / 12 * 5');
  left: -moz-calc(~'100% / 12 * 5');
}
.pull-5 {
  left: -41.66665%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 5');
  left: -webkit-calc(~'-100% / 12 * 5');
  left: -moz-calc(~'-100% / 12 * 5');
}
.push-6 {
  left: 50%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 6');
  left: -webkit-calc(~'100% / 12 * 6');
  left: -moz-calc(~'100% / 12 * 6');
}
.pull-6 {
  left: -50%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 6');
  left: -webkit-calc(~'-100% / 12 * 6');
  left: -moz-calc(~'-100% / 12 * 6');
}
.push-7 {
  left: 58.33333%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 7');
  left: -webkit-calc(~'100% / 12 * 7');
  left: -moz-calc(~'100% / 12 * 7');
}
.pull-7 {
  left: -58.33333%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 7');
  left: -webkit-calc(~'-100% / 12 * 7');
  left: -moz-calc(~'-100% / 12 * 7');
}
.push-8 {
  left: 66.66666%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 8');
  left: -webkit-calc(~'100% / 12 * 8');
  left: -moz-calc(~'100% / 12 * 8');
}
.pull-8 {
  left: -66.66666%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 8');
  left: -webkit-calc(~'-100% / 12 * 8');
  left: -moz-calc(~'-100% / 12 * 8');
}
.push-9 {
  left: 75%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 9');
  left: -webkit-calc(~'100% / 12 * 9');
  left: -moz-calc(~'100% / 12 * 9');
}
.pull-9 {
  left: -75%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 9');
  left: -webkit-calc(~'-100% / 12 * 9');
  left: -moz-calc(~'-100% / 12 * 9');
}
.push-10 {
  left: 83.33333%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 10');
  left: -webkit-calc(~'100% / 12 * 10');
  left: -moz-calc(~'100% / 12 * 10');
}
.pull-10 {
  left: -83.33333%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 10');
  left: -webkit-calc(~'-100% / 12 * 10');
  left: -moz-calc(~'-100% / 12 * 10');
}
.push-11 {
  left: 91.66666%; // Fallback if calc() is not supported
  left: calc(~'100% / 12 * 11');
  left: -webkit-calc(~'100% / 12 * 11');
  left: -moz-calc(~'100% / 12 * 11');
}
.pull-11 {
  left: -91.66666%; // Fallback if calc() is not supported
  left: calc(~'-100% / 12 * 11');
  left: -webkit-calc(~'-100% / 12 * 11');
  left: -moz-calc(~'-100% / 12 * 11');
}

.push-1-2 { &:extend(.push-6); }
.pull-1-2 { &:extend(.pull-6); }
.push-1-3 { &:extend(.push-4); }
.pull-1-3 { &:extend(.pull-4); }
.push-1-4 { &:extend(.push-3); }
.pull-1-4 { &:extend(.pull-3); }
.push-3-4 { &:extend(.push-9); }
.pull-3-4 { &:extend(.pull-9); }

// Rows
.row-zero { padding-top: 0; padding-bottom: 0; }
.row-xs { padding-top: @row-xs; padding-bottom: @row-xs; }
.row-s { padding-top: @row-s; padding-bottom: @row-s; }
.row-m, .row {padding-top: @row-m; padding-bottom: @row-m; }
.row-l { padding-top: @row-l; padding-bottom: @row-l; }
.row-xl { padding-top: @row-xl; padding-bottom: @row-xl; }
.row-xxl { padding-top: @row-xxl; padding-bottom: @row-xxl; }

.row-bottom-zero { padding-bottom: 0; }
.row-bottom-xs { padding-bottom: @row-xs; }
.row-bottom-s { padding-bottom: @row-s; }
.row-bottom-m { padding-bottom: @row-m; }
.row-bottom-l { padding-bottom: @row-l; }
.row-bottom-xl { padding-bottom: @row-xl; }
.row-bottom-xxl { padding-bottom: @row-xxl; }

.margin-horizontal {
  margin-left: 10px;
  margin-right: 10px;
}

.no-desktop { display: none; }

// Remove Margin / Padding
.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

@import "../_desktop-styles"; // Import Custom Desktop Styles